module.exports = {
  siteTitle: 'Événements "à part"', // <title>
  manifestName: 'Événements "à part"',
  manifestShortName: '"à part"', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#1b8d5c',
  manifestThemeColor: '#22bd5e',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/website-icon.png',
  pathPrefix: `/tesriensanslaterre/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Événements "à part"',
  subHeading: 'Organisation, production, création d’événements artistiques, concerts, résidences, stages...',
};

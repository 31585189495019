import React from 'react';

import { Link } from 'gatsby';

import config from '../../config';

export default function Footer() {
  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">Nous contacter</h2>
        <p>
          N’hésitez pas à vous présenter si vous souhaitez
          prendre part à ce rassemblement !
        </p>
        <form>
          <ul className="contact">
            <li className="fa-envelope-o">evenementapart@gmail.com</li>
            <li className="fa-phone">06 07 27 02 24</li>
            <li className="fa-envelope"><Link to="/newsletter">newsletter</Link></li>
          </ul>
        </form>
        <ul className="copyright">
          <li>&copy; evenement à part. All rights reserved.</li>
          <li>
            Development: <a href="https://aloisleclet.fr">Aloïs LECLET</a>
          </li>
          <li>
            Design: <a href="http://html5up.net">HTML5 UP</a>
          </li>
        </ul>
      </div>
    </section>
  );
}
